import React, { useState, useContext, useEffect, useRef } from 'react';
import Categoria from "../../Components/Carta/Categoria";
import Categorias from "../../Components/Carta/Categorias";
import '../../Styles/App.css';
import { SettingsContext } from "../../contexts/SettingsContext";
import { Badge, BottomNavigation, BottomNavigationAction, Grid, IconButton, Box, TextField, Button } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WifiIcon from '@mui/icons-material/Wifi';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import WebFont from 'webfontloader';
import { useMediaQuery } from "@mui/material";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Carrito from './components/Carrito';
import { useCategoria } from '../../Utils/UCategoria';
import Producto from '../../Components/Carta/Producto';
import Productos from './components/Productos';
import Marketplace from './components/marketplace/Marketplace';
import { host } from '../../Utils/Fetchs';
import FAMMSinFondo from '../../medias/FAMMSinFondo.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useLocation } from 'react-router-dom';

const NotificacionPago = ({ estado,location }) => {
    return (
        <Box style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
        }}>
            <Box style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '300px',
                maxWidth: '90%',
                position: 'relative'
            }}>
                {estado === 0 ? (
                    <>
                        <Box sx={{
                            width: '60px',
                            height: '60px',
                            borderRadius: '50%',
                            backgroundColor: '#00FF00' + '20',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '10px'
                        }}>
                            <CheckCircleIcon
                                sx={{
                                    fontSize: '40px',
                                    color: '#008000'
                                }}
                            />
                        </Box>
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#00FF00',
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }}
                        >
                            ¡Pago Exitoso!
                        </Typography>
                        <Typography
                            sx={{
                                color: '#666',
                                textAlign: 'center',
                                maxWidth: '280px'
                            }}
                        >
                            ¡Gracias por su compra!
                        </Typography>
                        <Button
                            onClick={() => window.location.href = location.pathname}
                            variant='contained'
                            sx={{
                                backgroundColor: '#008000',
                                color: 'white',
                                width: '100%',
                                marginTop: '10px',
                                '&:hover': {
                                    backgroundColor: '#008000' + 'DD',
                                }
                            }}
                        >
                            Aceptar
                        </Button>
                    </>
                ) : estado === 1 ? (
                    <>
                        <Box sx={{
                            width: '60px',
                            height: '60px',
                            borderRadius: '50%',
                            backgroundColor: '#B22222' + '20',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '10px'
                        }}>
                            <CheckCircleIcon
                                sx={{
                                    fontSize: '40px',
                                    color: '#B22222'
                                }}
                            />
                        </Box>
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#FF0000',
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }}
                        >
                            ¡Pago Fallido!
                        </Typography>
                        <Typography
                            sx={{
                                color: '#666',
                                textAlign: 'center',
                                maxWidth: '280px'
                            }}
                        >
                            ¡Hubo un error al procesar su pago!
                        </Typography>
                        <Button
                            onClick={() => window.location.href = location.pathname}
                            variant='contained'
                            sx={{
                                backgroundColor: '#B22222',
                                color: 'white',
                                width: '100%',
                                marginTop: '10px',
                                '&:hover': {
                                    backgroundColor: '#B22222' + 'DD',
                                }
                            }}
                        >
                            Cerrar
                        </Button>
                    </>
                ) : (
                    <>
                        <Box sx={{
                            width: '60px',
                            height: '60px',
                            borderRadius: '50%',
                            backgroundColor: '#FFD700' + '20',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '10px'
                        }}>
                            <CheckCircleIcon
                                sx={{
                                    fontSize: '40px',
                                    color: '#FFD700'
                                }}
                            />
                        </Box>
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#FFD700',
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }}
                        >
                            ¡Pago Pendiente!
                        </Typography>
                        <Typography
                            sx={{
                                color: '#666',
                                textAlign: 'center',
                                maxWidth: '280px'
                            }}
                        >
                            ¡Su pago está siendo procesado!
                        </Typography>
                </>
                )}
            </Box>
        </Box>
    );
}

const ModalPerfil = ({ open, onClose, settings, afiliadoConfirmado, setAfiliadoConfirmado }) => { 
    return (
        <Box style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
        }}>
            <Box style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '300px',
                maxWidth: '90%',
                position: 'relative'
            }}>
                {afiliadoConfirmado ? (
                    <>
                        <Box sx={{
                            width: '60px',
                            height: '60px',
                            borderRadius: '50%',
                            backgroundColor: settings?.background_color3 + '20',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '10px'
                        }}>
                            <CheckCircleIcon
                                sx={{
                                    fontSize: '40px',
                                    color: settings?.background_color3
                                }}
                            />
                        </Box>
                        <Typography
                            variant="h6"
                            sx={{
                                color: settings?.background_color3,
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }}
                        >
                            ¡DNI Verificado!
                        </Typography>
                        <Typography
                            sx={{
                                color: '#666',
                                textAlign: 'center',
                                maxWidth: '280px'
                            }}
                        >
                            ¡Ya tenes acceso a los precios exclusivos de FAMM!
                        </Typography>
                        <Button
                            onClick={onClose}
                            variant='contained'
                            sx={{
                                backgroundColor: settings?.background_color3,
                                color: settings?.color2,
                                width: '100%',
                                marginTop: '10px',
                                '&:hover': {
                                    backgroundColor: settings?.background_color3 + 'DD',
                                }
                            }}
                        >
                            Entendido
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography variant="h6" sx={{ color: '#333' }}>
                            Ingrese su DNI
                        </Typography>
                        <TextField
                            label="DNI"
                            fullWidth
                            variant="outlined"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: settings?.background_color3,
                                    },
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: settings?.background_color3,
                                },
                            }}
                        />
                        <Button
                            onClick={() => {
                                setAfiliadoConfirmado(true);
                                onClose();
                            }}
                            variant='contained'
                            fullWidth
                            sx={{
                                backgroundColor: settings?.background_color3,
                                color: settings?.color2,
                                '&:hover': {
                                    backgroundColor: settings?.background_color3 + 'DD',
                                }
                            }}
                        >
                            Verificar
                        </Button>
                    </>
                )}
            </Box>
        </Box>
    );
}

export default function CartaPage() {
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
    const [actualScreen, setActualScreen] = useState(0);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const wifiLogoRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:450px)');
    const [modalPerfil, setModalPerfil] = useState(false);

    const handleWifiDialog = () => {
        setOpen(!open);
    };

    const { settings, wifi, getSettings, planes, getCantidadCarrito, getEmpresa, carritoDrawer, setCarritoDrawer, afiliadoConfirmado, setAfiliadoConfirmado } = useContext(SettingsContext)

    useEffect(() => {
        getSettings();
    }, [])

    const location = useLocation();
    const [estadoPago, setEstadoPago] = useState(null);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has('pago')) {
            setEstadoPago(parseInt(queryParams.get('pago')));
        }
    }, [location.search]);

    useEffect(() => {
        const metaThemeColor = document.querySelector('meta[name=theme-color]');
        metaThemeColor.setAttribute('content', settings?.background_color2);
    }, [settings])

    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(true);

    const { getCategorias } = useCategoria();

    useEffect(() => {
        getCategorias(getEmpresa())
            .then(response => {
                setCategorias(response);
                if (response.length > 0) {
                    setCategoriaSeleccionada(response[0].id);
                    setCategoriaSeleccionada(response[0]);
                }
            })
            .catch(error => {
                console.error('Error al cargar categorías:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getEmpresa()]);

    useEffect(() => {
        if (settings?.font_family) {
            WebFont.load({
                google: {
                    families: [settings?.font_family]
                }
            });
        }
    }, [settings?.font_family]);

    const handleCategoriaSeleccionada = (categoria) => {
        setCategoriaSeleccionada(categoria);
    };

    const secciones = [
        <div style={{
            marginTop: '80px',
            height: "100%",
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
        }}>
            <Categorias
                setCategoriaSeleccionada={handleCategoriaSeleccionada}
                loading={loading}
                categorias={categorias}
                categoriaSeleccionada={categoriaSeleccionada} />

            {categoriaSeleccionada && <Productos categoria={categoriaSeleccionada} color={settings?.background_color3} />}
        </div>
    ]

    if (settings?.tipo === '3') {
        return (
            <Marketplace />
        )
    }

    if (settings) return (
        <Grid container>
            <div style={{
                backgroundColor: settings?.background_color1,
                height: '100vh',
                overflow: 'hidden',
                paddingBottom: '56px',
            }} className="display-col widthmax">
                <div style={{
                    height: '0',
                    position: 'fixed',
                    zIndex: 1000,
                    alignItems: 'center',
                    justifyItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}>
                    <header className="align-items-center display-row"
                        style={{
                            backgroundColor: settings?.background_color2,
                            fontSize: isMobile ? '0.6em' : '0.8em',
                            width: '100%',
                            height: '75px',
                            minHeight: '75px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '0 10px',
                        }}>
                        {planes.includes('5') && (
                            <div onClick={() => window.location.href = '/famm'} style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                width: 'auto',
                                cursor: 'pointer'
                            }}>
                                <img src={FAMMSinFondo} alt="logo" style={{
                                    width: 'auto',
                                    height: '100%',
                                    objectFit: 'contain',
                                    maxWidth: '30vw',
                                }} />
                            </div>
                        )}
                        <div style={{
                            flex: 1,
                            textAlign: 'center',
                        }}>
                            {settings?.logo ? (
                                <img src={`${host}/media/${settings?.logo}`} alt="logo" style={{
                                    width: 'auto',
                                    maxWidth: '45vw',
                                    height: '100%',
                                    maxHeight: '75px',
                                }} />
                            ) : (
                                <h1 style={{
                                    color: settings?.color1,
                                    fontFamily: settings?.font_family,
                                    marginLeft: planes.includes('5') ? '-10%' : ''
                                }}>
                                    {settings?.nombre}
                                </h1>
                            )}
                        </div>
                    </header>

                    <SwipeableDrawer
                        anchor={'bottom'}
                        open={carritoDrawer}
                        onClose={() => setCarritoDrawer(false)}
                        onOpen={() => setCarritoDrawer(true)}
                    >
                        <Carrito />
                    </SwipeableDrawer>
                </div>
                {secciones[actualScreen]}
            </div>
            {((wifi.red && wifi.clave) || planes.includes('1')) &&
                <BottomNavigation
                    showLabels
                    sx={{
                        backgroundColor: settings?.background_color2,
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        zIndex: 1,
                        '& .Mui-selected .MuiSvgIcon-root': {
                            color: settings?.background_color3,
                        },

                        '& .Mui-selected': {
                            color: settings?.background_color3,
                        }

                    }}
                    value={actualScreen}
                    onChange={(event, newValue) => {
                        if (newValue === 1) {
                            setOpen(true);
                            return;
                        }
                    }}
                >
                    <BottomNavigationAction
                        icon={
                            <>
                                <MenuBookIcon style={{ color: settings?.color2 }} />
                                <Typography style={{ color: settings?.color2, fontFamily: settings?.font_family, fontSize: '0.8rem' }}>
                                    {settings?.tipo === '1' ? 'Menú' : 'Catalogo'}
                                </Typography>
                            </>
                        }
                    />
                    {wifi.red && wifi.clave &&
                        <BottomNavigationAction
                            label="Wi Fi"
                            ref={wifiLogoRef}
                            sx={{
                                color: settings?.color2,
                                fontFamily: settings?.font_family
                            }}
                            icon={
                                <WifiIcon />
                            }
                        />}
                    {planes.includes('1') &&
                        <BottomNavigationAction
                            onClick={() => setCarritoDrawer(true)}
                            label="Carrito"
                            sx={{
                                color: settings?.color2,
                                fontFamily: settings?.font_family
                            }}
                            icon={
                                <Badge badgeContent={getCantidadCarrito()} sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: settings?.background_color3,
                                        color: settings?.color2,
                                    }

                                }}>
                                    <LocalGroceryStoreIcon />
                                </Badge>
                            }
                            ref={anchorRef}
                        />}
                    {planes.includes('5') && (
                        <BottomNavigationAction
                            label="Afiliado FAMM"
                            sx={{
                                color: !afiliadoConfirmado ? settings?.color2 : '#EF9900',
                                fontFamily: settings?.font_family
                            }}
                            icon={<AccountCircleIcon />}
                            onClick={() => setModalPerfil(true)}
                        />
                    )}
                </BottomNavigation>
            }
            <Popover
                open={open}
                anchorEl={wifiLogoRef.current}
                onClose={handleWifiDialog}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        height: '200px',
                        width: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        clipPath: 'polygon(0% 0%, 100% 0%, 100% 80%, 50% 100%, 0% 80%)',
                        position: 'relative',
                        overflow: 'visible',
                        borderRadius: '30px 30px 90px 90px',
                    },
                }}
            >
                <Typography style={{
                    marginBottom: '15px', marginTop: '15px',
                    color: 'black', fontSize: '1.2em', fontFamily: settings?.font_family
                }}>
                    Red: {wifi.red}
                </Typography>
                <Typography style={{ color: 'black', fontSize: '1.2em', fontFamily: settings?.font_family }}>
                    Clave: {wifi.clave}
                </Typography>
                <IconButton
                    style={{
                        position: 'absolute',
                        bottom: '30px',
                        color: 'black',
                        transition: 'transform 0.4s ease',
                    }}
                    onClick={(e) => {
                        navigator.clipboard.writeText(wifi.clave);
                        const target = e.currentTarget;
                        if (target) {
                            target.style.transform = 'scale(1.5)';
                            setTimeout(() => {
                                target.style.transform = 'scale(1)';
                            }, 200);
                        }
                    }}
                >
                    <ContentCopyIcon style={{ fontSize: '1.5em' }} />
                </IconButton>
            </Popover>
            {modalPerfil && (
                <ModalPerfil
                    open={modalPerfil}
                    onClose={() => setModalPerfil(false)}
                    settings={settings}
                    afiliadoConfirmado={afiliadoConfirmado}
                    setAfiliadoConfirmado={setAfiliadoConfirmado}
                />
            )} 
            { estadoPago !== null && <NotificacionPago estado={estadoPago} location={location} /> }
        </Grid> 
    );
}
