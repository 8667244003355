import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useFetch, { host } from '../../../../Utils/Fetchs';
import { SettingsContext } from '../../../../contexts/SettingsContext';
import Header from './components/Header';
import VideoRender from './components/VideoRender';
import FAMM from '../../../../medias/FAMM.png';
import FAMMCamara from '../../../../medias/FAMMCamara.png';

export default function Marketplace() {
    const [rubros, setRubros] = useState([]);
    const [rubroSeleccionado, setRubroSeleccionado] = useState(null);
    const [showVideo, setShowVideo] = useState(null);
    const [anuncio, setAnuncio] = useState(null);
    const [showAnuncio, setShowAnuncio] = useState(false);
    const [empresas, setEmpresas] = useState([]);

    const { getFetch } = useFetch();
    const { getEmpresa } = useContext(SettingsContext);

    const isMobile = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        getFetch(`${host}rubros/${getEmpresa()}/`)
            .then(data => {
                setAnuncio(data.anuncio);
                if (!data.anuncio) setShowAnuncio(false);
                console.log(data.rubros);
                setRubros(data.rubros);
                const todasLasEmpresas = data.rubros
                    .map(rubro => rubro.empresas)
                    .flat();

                setEmpresas(todasLasEmpresas);
            })
            .catch(error => console.error(error));
    }, []);

    // if (showAnuncio && anuncio) {
    //     return (
    //         <Modal
    //             open={showAnuncio}
    //             onClose={() => setShowAnuncio(false)}
    //             disableAutoFocus
    //             sx={{
    //                 display: 'flex',
    //                 justifyContent: 'center',
    //                 alignItems: 'center',
    //             }}
    //         >
    //             <Box sx={{
    //                 position: 'relative',
    //                 width: "90%"
    //             }}>
    //                 <img
    //                     src={host + anuncio}
    //                     style={{ width: '100%', cursor: 'pointer' }}
    //                     onClick={() => setShowAnuncio(false)}
    //                 />
    //                 <Box
    //                     sx={{
    //                         position: 'fixed',
    //                         top: '10px',
    //                         right: '10px',
    //                         color: 'white',
    //                         cursor: 'pointer',
    //                     }}
    //                     onClick={() => setShowAnuncio(false)}
    //                 >
    //                     <CloseIcon />
    //                 </Box>
    //             </Box>
    //         </Modal>
    //     );
    // }

    if (!showAnuncio) {
        return (
            <Box sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}>
                <Header
                    rubros={rubros}
                    setRubroSeleccionado={setRubroSeleccionado}
                    rubroSeleccionado={rubroSeleccionado}
                />
                <Box sx={{
                    flex: 1,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    mt: 7
                }}>
                    <Box sx={{
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}>
                        <img
                            src={FAMM}
                            style={{
                                width: '80%',
                                maxWidth: '450px',
                                height: 'auto'
                            }}
                        />
                    </Box>

                    <Box sx={{
                        flex: 1,
                        overflow: 'auto',
                        padding: '0 20px 20px 20px',
                        "&::-webkit-scrollbar": {
                            display: "none",
                        }
                    }}>
                        <Grid
                            container
                            sx={{
                                gap: '20px',
                                display: 'grid',
                                gridTemplateColumns: rubroSeleccionado
                                    ? (isMobile ? 'repeat(2, 1fr)' : 'repeat(6, 1fr)')
                                    : (isMobile ? '1fr' : 'repeat(2, 1fr)'),
                                alignItems: 'start',
                            }}
                        >
                            {rubroSeleccionado ? (
                                rubroSeleccionado.empresas?.map((empresa, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '250px',
                                            width: '100%',
                                            padding: '10px',
                                            cursor: 'pointer',
                                            backgroundColor: 'white',
                                        }}
                                        onClick={() => setShowVideo(empresa)}
                                    >
                                        <Box sx={{
                                            flex: 1,
                                            minHeight: 0,
                                            position: 'relative',
                                            border: "1px solid",
                                            borderImage: "linear-gradient(#FD00FF, #EF9900) 1",
                                            borderRadius: '10px',
                                            padding: empresa.logo !== null ? "5px" : "30px",
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <img
                                                src={empresa.logo !== null ? host + empresa.logo : FAMMCamara}
                                                alt={empresa.nombre}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                    maxHeight: '175px',
                                                    maxWidth: '100%',
                                                }}
                                            />
                                        </Box>

                                        <Typography
                                            sx={{
                                                color: '#EF9900',
                                                fontWeight: 'bold',
                                                fontSize: '1.2rem',
                                                marginTop: '8px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                width: '100%',
                                                height: '28px', // Fixed height for title
                                                lineHeight: '28px',
                                            }}
                                        >
                                            {empresa.nombre}
                                        </Typography>

                                        <Typography
                                            sx={{
                                                color: 'black',
                                                fontSize: '0.9rem',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                width: '100%',
                                                height: '22px', // Fixed height for description
                                                lineHeight: '22px',
                                            }}
                                        >
                                            {empresa.descripcion}
                                        </Typography>
                                    </Box>
                                ))
                            ) : (
                                empresas?.map((empresa, index) => (
                                    index > 5 ? null :
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                height: '150px',
                                                width: '100%',
                                                padding: '10px',
                                                cursor: 'pointer',
                                                backgroundColor: 'white',
                                                borderBottom: '1.5px solid',
                                                borderImage: 'linear-gradient(to right, #FD00FF, #EF9900) 1',
                                            }}
                                            onClick={() => setShowVideo(empresa)}
                                        >
                                            {/* Imagen de la empresa */}
                                            <Box sx={{
                                                width: '30%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '5px',
                                            }}>
                                                <img
                                                    src={empresa.logo !== null ? host + empresa.logo : FAMMCamara}
                                                    alt={empresa.nombre}
                                                    style={{
                                                        maxWidth: '100%',
                                                        maxHeight: '100%',
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            </Box>

                                            {/* Contenido a la derecha de la imagen */}
                                            <Box sx={{
                                                width: '70%',
                                                paddingLeft: '10px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}>
                                                <Typography
                                                    sx={{
                                                        color: '#EF9900',
                                                        fontWeight: 'bold',
                                                        fontSize: '1.5rem',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {empresa.nombre}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: 'black',
                                                        fontSize: '0.9rem',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {empresa.descripcion}
                                                </Typography>
                                            </Box>
                                        </Box>
                                ))
                            )}
                        </Grid>
                    </Box>
                </Box>
                <VideoRender empresa={showVideo} onClose={() => setShowVideo(null)} />
            </Box>
        );
    }
}